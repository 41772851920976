function transformToPushNotificationUpdateModel(pushNotification) {
  return {
    name: pushNotification.name,
    title: {
      en: pushNotification.title.en,
      bg: pushNotification.title.bg
    },
    body: {
      en: pushNotification.body.en,
      bg: pushNotification.body.bg
    }
  };
}

export default {
  transformToPushNotificationUpdateModel
};
