<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-title>
        <span v-if="this.pushNotification.id">
          {{ $t('pushNotifications.editPushNotification') }}
        </span>
        <span v-else>{{ $t('pushNotifications.createPushNotification') }}</span>
        <div class="flex-grow-1"></div>
        <v-btn depressed color="primary" to="/push-notifications">
          <v-icon>mdi-chevron-left</v-icon>
          <span>{{ $t('pushNotifications.backToPushNotifications') }}</span>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <manage-push-notification-form
          v-if="!loading"
          :editted-push-notification="pushNotification"
        ></manage-push-notification-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ManagePushNotificationForm from '../components/ManagePushNotificationForm';
import httpService from '../services/push.notifications.http.service';

export default {
  name: 'manage-push-notifications-page',
  components: {
    ManagePushNotificationForm
  },
  data() {
    return {
      pushNotification: {},
      loading: false
    };
  },
  async created() {
    const id = this.$route.params.id;

    if (id) {
      this.loading = true;

      const { result } = await httpService.get(id);
      this.pushNotification = result;

      this.loading = false;
    }
  }
};
</script>
