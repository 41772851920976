<template>
  <v-form ref="managePushNotificationForm">
    <v-row>
      <v-col md="12" class="py-0">
        <h3>{{ $t('common.name') }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-text-field
          solo
          class="pa-0 my-0"
          v-model="pushNotification.name"
          :rules="[rules.required]"
          :label="$t('common.name')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="pb-0">
        <h3>{{ $t('common.title') }}</h3>
      </v-col>
      <v-col md="6" sm="12">
        <v-text-field
          solo
          v-model="pushNotification.title.en"
          :rules="[rules.required]"
          :label="$t('languages.en')"
        ></v-text-field>
      </v-col>
      <v-col md="6" sm="12">
        <v-text-field
          solo
          v-model="pushNotification.title.bg"
          :rules="[rules.required]"
          :label="$t('languages.bg')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="pb-0">
        <h3>{{ $t('common.text') }}</h3>
      </v-col>
      <v-col md="6" sm="12">
        <v-textarea
          solo
          v-model="pushNotification.body.en"
          :label="$t('languages.en')"
        ></v-textarea>
      </v-col>
      <v-col md="6" sm="12">
        <v-textarea
          solo
          v-model="pushNotification.body.bg"
          :label="$t('languages.bg')"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        v-if="pushNotification.id"
        class="mr-4"
        @click="sendNotification()"
        :disabled="
          pushNotification.status === pushNotificationStatuses.inProgress
        "
      >
        {{ $t('pushNotifications.sendNotification') }}
      </v-btn>
      <v-btn color="primary" class="mr-4" @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import ConfirmModal from '../../shared/components/ConfirmModal';
import validators from '../../../core/validators';
import httpService from '../services/push.notifications.http.service';
import mapper from '../mappers/push.notifications.mapper';
import pushNotificationStatuses from '../../../enums/push.notification.statuses';

export default {
  name: 'manage-board-form',
  props: {
    edittedPushNotification: {
      type: Object
    }
  },
  data() {
    return {
      pushNotificationStatuses,
      pushNotification: {
        name: '',
        title: {
          en: '',
          bg: ''
        },
        body: {
          en: '',
          bg: ''
        }
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField'))
      }
    };
  },
  created() {
    if (this.edittedPushNotification.id) {
      this.pushNotification = {
        ...this.edittedPushNotification
      };
    }
  },
  methods: {
    async sendNotification() {
      if (
        this.pushNotification.status === pushNotificationStatuses.inProgress
      ) {
        this.$notify({
          type: 'warning',
          text: this.$i18n.t(
            'pushNotifications.cannotSendInProgressNotification'
          )
        });

        return;
      }

      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('pushNotifications.sendConfirmText')
      });

      if (!confirmed) return;

      await httpService.send(this.pushNotification.id);
    },
    async save() {
      if (!this.$refs.managePushNotificationForm.validate()) {
        return;
      }

      if (this.pushNotification.id) {
        await httpService.update(
          this.pushNotification.id,
          mapper.transformToPushNotificationUpdateModel(this.pushNotification)
        );

        this.$notify({
          type: 'success',
          text: this.$i18n.t('pushNotifications.updateSuccess')
        });
      } else {
        await httpService.create(this.pushNotification);

        this.$notify({
          type: 'success',
          text: this.$i18n.t('pushNotifications.createSuccess')
        });
      }

      this.$router.push({ name: 'push-notifications' });
    }
  }
};
</script>
